<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-tabs class="mb-3">
        <v-tab @click="loadRow(0)">Активные</v-tab>
        <v-tab @click="loadRow(1)">Не активные</v-tab>
      </v-tabs>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        loading-text="Идёт загрузка данных..."
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :footer-props="footerProps"
      >
        <template v-slot:[`item.index`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="!deleted" icon
            ><v-icon @click="handleClick(item)" small class="mr-2">
              mdi-pencil
            </v-icon></v-btn
          >
          <v-btn icon v-if="is_admin && !deleted"
            ><v-icon @click="deletePlace(item)" small class="mr-2" color="red">
              mdi-delete
            </v-icon></v-btn
          >
          <v-btn v-if="deleted" icon
            ><v-icon
              @click="deletePlace(item)"
              color="success"
              small
              class="mr-2"
            >
              mdi-power
            </v-icon></v-btn
          >
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="create"> Добавить </v-btn>
    </v-card>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline"> Название местности </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="locationName.name"
            label="Введите местность"
            dense
          />
          <v-text-field
            v-model="locationName.slug"
            label="Введите slug"
            dense
          />
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="locationName.latitude"
                label="Широта"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="locationName.longitude"
                label="Долгота"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-file-input
            v-model="locationName.main_image"
            label="Добавить файл"
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <v-row
            class="pl-4 pr-3 mt-3"
            align="center"
            v-for="item in locationName.places"
            :key="item.index"
          >
            <v-text-field
              v-model="item.name"
              label="Введите подместность"
              dense
            />
            <v-btn @click="deleteSubPlaceOnCreate(item.index)">Удалить</v-btn>
          </v-row>

          <v-btn @click="addSubplaceOnCreate">Добавить подместность</v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Назад </v-btn>
          <v-btn color="primary" text @click="saveName" :disabled="disabledBtn">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subplacedialog" max-width="600">
      <v-card>
        <v-card-title class="headline"> Подместность </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="red_place.latitude"
                label="Широта"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="red_place.longitude"
                label="Долгота"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-3" align="center">
            <v-text-field
              v-model="red_place.name"
              label="Введите местность"
              dense
            />
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <v-text-field
                v-model="red_place.slug"
                label="Введите slug"
                dense
              />
            </v-col>
          </v-row>
          <span v-if="red_place.main_image">Загруженная картинка: </span
          ><img
            class="main_image"
            v-if="red_place.main_image"
            :src="red_place.main_image"
            alt=""
          />
          <v-file-input
            v-if="red_place.main_image"
            v-model="red_place.main_image1"
            :label="red_place.main_image ? 'Изменить файл' : 'Добавить файл'"
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <v-file-input
            v-if="!red_place.main_image"
            v-model="red_place.main_image1"
            :label="red_place.main_image ? 'Изменить файл' : 'Добавить файл'"
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <v-btn
            small
            class="ml-2 mb-3"
            @click="edtiPlaceName(red_place)"
            :disabled="disabledBtn"
          >
            Сохранить местность
          </v-btn>

          <v-row v-for="(item, i) in sub_places" :key="i">
            <v-list min-width="100%">
              <v-list-item-group v-if="!item.isEdit">
                <v-list-item @click="changeToInput(item, i)">
                  <v-list-item-content>{{ item.name }}</v-list-item-content>
                  <v-icon small class="mr-2"> mdi-pencil </v-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-row class="pl-4 pr-3" align="center" v-if="item.isEdit">
              <v-text-field
                v-model="item.name"
                label="Введите подместность"
                dense
              />
              <v-btn
                small
                class="ml-2 mb-3"
                @click="edtiSubplaceItem(item, i)"
                :disabled="disabledBtn"
              >
                Сохранить
              </v-btn>
            </v-row>
          </v-row>
          <v-row v-if="isSubplpace" align="center" class="mt-5">
            <v-text-field
              v-model="sub_place"
              label="Введите подместность"
              dense
            />
            <v-btn
              small
              class="ml-2 mb-3"
              @click="createSubPlace"
              :disabled="disabledBtn"
            >
              Сохранить
            </v-btn>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="subplacedialog = false">
            Отмена
          </v-btn>
          <v-btn color="primary" @click="addSubplace" text>
            Добавить подместность
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import { LocationApiUrls } from "@/services/accommodationRequests/location.api.js";
export default {
  name: "Location",
  data: () => ({
    disabledBtn: false,
    overlay: false,
    isSubplpace: false,
    locationName: {
      places: [{ name: null, index: 0 }]
    },
    dialog: false,
    subplacedialog: false,
    sub_place: "",
    sub_places: [],
    red_place: {
      id: null,
      name: null,
      latitude: null,
      longitude: null
    },
    loading: true,
    headers: [
      { text: "№", value: "index", class: "table-title" },
      {
        text: "Название",
        value: "name",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Подместность",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    items: [],
    footerProps: {
      "items-per-page-options": [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "30",
          value: 30
        },
        {
          text: "Все",
          value: 99999999999
        }
      ]
    },
    options: {},
    firstWatcherTrigger: false,
    is_admin: false,
    deleted: false
  }),
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    this.is_admin = JSON.parse(localStorage.getItem("is_admin"));
    this.loadRow();
  },
  methods: {
    async deletePlace(item) {
      try {
        await LocationApiUrls.deleteCity(item.id);
        this.loadRow();
      } catch (e) {
        console.log(e);
      }
    },
    deleteSubPlaceOnCreate(index) {
      this.locationName.places.splice(index, 1);
    },
    addSubplaceOnCreate() {
      if (!this.locationName.places) this.locationName.places = [];
      this.locationName.places.splice(this.locationName.places.length, 0, {
        name: null,
        index: this.locationName.places.length
      });
    },
    async edtiSubplaceItem(item, i) {
      this.sub_places[i].isEdit = false;
      let putData = {
        name: item.name,
        city: item.id
      };
      this.disabledBtn = true;
      await LocationApiUrls.setPlace(item.id, putData);
      this.disabledBtn = false;
      this.sub_places[i].isEdit = false;
    },
    changeToInput(item, i) {
      this.sub_places[i].isEdit = true;
      let arr = [...this.sub_places];
      this.sub_places = [];
      this.sub_places = [...arr];
    },
    async createSubPlace() {
      let postData = {
        name: this.sub_place,
        city: this.red_place.id
      };
      this.disabledBtn = true;
      let res = await LocationApiUrls.createPlace(postData);
      this.disabledBtn = false;
      res.isEdit = false;
      this.sub_places.push(res);
      this.isSubplpace = false;
      this.sub_place = "";
    },
    addSubplace() {
      this.isSubplpace = true;
    },
    async edtiPlaceName() {
      this.disabledBtn = true;
      var formdata = new FormData();
      formdata.append("name", this.red_place.name);
      formdata.append("latitude", this.red_place.latitude);
      formdata.append("longitude", this.red_place.longitude);
      formdata.append("slug", this.red_place.slug);
      if (this.red_place.main_image1)
        formdata.append("main_image", this.red_place.main_image1);

      try {
        await LocationApiUrls.setCity(this.red_place.id, formdata);
        this.disabledBtn = false;
        toast.success("Успешно изменили название");
        this.loadRow();
      } catch (e) {
        if (e.response.status > 499) {
          toast.error("Извините, ошибка на сервере!");
        } else {
          for (const [key, value] of Object.entries(e.response.data)) {
            value.forEach(element => {
              toast.error(key + ": " + element);
            });
          }
        }
      }
    },
    async handleClick(value) {
      this.overlay = true;
      let params = {};
      params.city_id = value.id;
      this.sub_places = await LocationApiUrls.getPlaces(params);
      for (let k of this.sub_places) {
        k.isEdit = false;
      }
      this.subplacedialog = true;
      this.red_place = value;
      this.overlay = false;
    },
    async saveName() {
      let body = {};
      if (this.locationName.name) body["name"] = this.locationName.name;
      if (this.locationName.latitude)
        body["latitude"] = this.locationName.latitude;
      if (this.locationName.longitude)
        body["longitude"] = this.locationName.longitude;
      if (this.locationName.main_image)
        body["main_image"] = this.locationName.main_image;
      if (this.locationName.slug) body["slug"] = this.locationName.slug;
      if (this.locationName.places) body["places"] = this.locationName.places;
      if (
        this.locationName.name &&
        this.locationName.latitude &&
        this.locationName.longitude &&
        this.locationName.main_image &&
        this.locationName.slug &&
        this.locationName.places
      ) {
        this.disabledBtn = true;

        let res = await LocationApiUrls.createCity(body);
        let formdata = new FormData();
        formdata.append("main_image", this.locationName.main_image);
        await LocationApiUrls.setImage(res.id, formdata);

        this.disabledBtn = false;
        this.dialog = false;
        this.loadRow();
      } else {
        toast.error("Заполните все поля!");
      }
    },
    async loadRow(value = null) {
      this.items = [];
      this.loading = true;
      if (value !== null) this.deleted = value;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        deleted: this.deleted ? true : false
      };
      this.items = await LocationApiUrls.getCities(params);
      this.loading = false;
    },
    create() {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.main_image {
  width: 70%;
}
</style>
